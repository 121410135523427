import { useState } from "react";
import { useDispatch } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';


import { changeLicensePolicy } from "../../../redux/actions/licenses/licenseActions";

const policyNames = [
    '1-Channel Samurai Count',
    '2-Channel Samurai Count',
    '4-Channel Samurai Count',
    '1-Channel Samurai Detect',
    '2-Channel Samurai Detect',
    '4-Channel Samurai Detect',
    '6-Channel Health Monitoring',
    '50-Channel Health Monitoring',
    '100-Channel Health Monitoring',
    '4-Channel Parking Management',
    '2-Channel Parking Management',
    '4-Channel Corsight',
    '2-channel Object'
  ];



const LicenseEditForm = ({ closeLicenseEdit, policy, licenseKey }) => {
    const [selectedPolicy, setSelectedPolicy] = useState('');
   
    const dispatch = useDispatch();

    const filteredPolicies = policyNames.filter(policyName => policyName !== policy);   

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(`key = ${licenseKey}, selectedPolicy = ${selectedPolicy}`)
        dispatch(changeLicensePolicy({licenseKey, selectedPolicy}));
    }

    return (
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>

        <div style={{ fontSize: '0.95rem', fontWeight: '500', marginBottom: '0.8rem'  }}>
            Your existing policy: <span style={{ color: 'gray', fontSize: '0.9rem' }}>{policy}</span>
        </div>        

           <Form.Group className="mb-3">           
            <Form.Control
            style={{ width: '90%'}}
                size="sm"
                as="select"
                value={selectedPolicy}
                onChange={(e) => setSelectedPolicy(e.target.value)}
                >
                <option value="">Select a different policy</option>
                {filteredPolicies.map((policyName, index) => (
                    <option key={index} value={policyName}>
                        {policyName}
                    </option>
                ))}
            </Form.Control>
           </Form.Group>
          

           <Button variant="primary" type="submit">
                Submit
            </Button>

        </Form>
    )  
}

export default LicenseEditForm;