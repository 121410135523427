import React, {useState, useEffect, useMemo} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';

import SideBar from '../SideBar';
import ServerLifecycle from './ServerLifecycle';
import Footer from '../Footer';

import ServerLifecycleChart from './charts/ServerLifecycleChart';
import CameraLifecycleChart from './charts/CameraLifecycleChart';

import { listBusinesses } from '../../redux/actions/listBusinesses';
import { listSites } from '../../redux/actions/listSites';

import { BeatLoader } from 'react-spinners';
import { RingLoader } from 'react-spinners';

import '../../../src/styles/lifecycle.css';
import './charts/lifecyclecharts.css';
import './lifecycle.css';

const heartIcon = require('./charts/images/lifecycleHeart.png');
const timeIcon = require('./charts/images/time.png');



const Lifecycle = () => {

    const [date,setDate] = useState(new Date());   
    const [selectedSite, setSelectedSite] = useState('All');
    const [selectedPeriod, setSelectedPeriod] = useState('365');
    const [selectedServerLifeExpectancyDays, setSelectedServerLifeExpectancyDays] = useState('1095');
    const [selectedCameraLifeExpectancyDays, setSelectedCameraLifeExpectancyDays] = useState('1825');
    const [selectedCriticalDaysServer, setSelectedCriticalDaysServer] = useState('20');
    const [selectedCriticalDaysCamera, setSelectedCriticalDaysCamera] = useState('10');
    const businesses = useSelector(state => state.listBusinesses); 
    const [selectedBusiness, setSelectedBusiness] = useState(businesses[0]);
    const [isSwitchOnServerExpired, setIsSwitchOnServerExpired] = useState(false);
    const [isSwitchOnServerCritical, setIsSwitchOnServerCritical] = useState(false);
    const [isSwitchOnServerHealthy, setIsSwitchOnServerHealthy] = useState(false);
    const [isSwitchOnCameraExpired, setIsSwitchOnCameraExpired] = useState(false);
    const [isSwitchOnCameraCritical, setIsSwitchOnCameraCritical] = useState(false);
    const [isSwitchOnCameraHealthy, setIsSwitchOnCameraHealthy] = useState(false);
    const [isSwitchOnServerLifeExpectancy, setIsSwitchOnServerLifeExpectancy] = useState(false);
    const [isSwitchOnServerNoMaintenance, setIsSwitchOnServerNoMaintenance] = useState(false);
    const [sortConfigServerLifecycle, setSortConfigServerLifecycle] = useState({ key: 'operatingDays', direction: 'ascending' });

    const serverLifecycleData = useSelector(state => state.listSites);

    const handleSortServerLifecycle = (columnKey) => {
        let direction = 'ascending';
        if (sortConfigServerLifecycle.key === columnKey && sortConfigServerLifecycle.direction === 'ascending') {
          direction = 'descending';
        }
        setSortConfigServerLifecycle({ key: columnKey, direction });
    };

    const renderSortIconServerLifecycle = (columnKey) => {
        if (sortConfigServerLifecycle.key === columnKey) {
          return sortConfigServerLifecycle.direction === 'ascending' ? '▲' : '▼';
        }
        // Default to ascending arrow if not sorted yet
        return '▲';
      };

    function calculateDaysPassed(startDateUTC) {
        // Parse the start date as a UTC date
        const start = new Date(startDateUTC);
        // Get the current date in UTC
        const now = new Date();
        // Calculate the difference in milliseconds
        const diffInMilliseconds = now - start;
        // Convert the difference to days
        const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
        return diffInDays;
    }

    let uniqueSites = [...new Set(serverLifecycleData.map(each => each.site?.site))];
    // sort sites alphabetically
    uniqueSites = uniqueSites.sort((a, b) => a.localeCompare(b));

    const loading = useSelector(state => state.loading);
    const loadingServerLifecycleUpdate = useSelector(state => state.loadingServerLifecycleUpdate);

    // insert integere value in an array to store critical days range form 1 to selectedPeriod
    const criticalDaysRange = Array.from({length: parseInt(selectedPeriod)}, (_, i) => i + 1);

    // filter sites based on selected one
  const filteredServerLifecycleData =
  selectedSite === 'All'
    ? serverLifecycleData
    : serverLifecycleData.filter((each) =>
        each.site.site === selectedSite
    );


    // sort by server lifecycle days
  let sortedFilteredServerLifecycleData = [...filteredServerLifecycleData].sort(
    (a, b) => calculateDaysPassed(b.serverFirstCheckin) - calculateDaysPassed(a.serverFirstCheckin)
    );



    // toggle for server expired lifecycle
    if (isSwitchOnServerExpired) {
        sortedFilteredServerLifecycleData = sortedFilteredServerLifecycleData.filter(
            each => calculateDaysPassed(each.serverFirstCheckin) > selectedPeriod);
    }

    // toggle for server critical lifecycle
    if (isSwitchOnServerCritical) {
    sortedFilteredServerLifecycleData = sortedFilteredServerLifecycleData.filter(
        each => parseInt(selectedPeriod) - calculateDaysPassed(each.serverFirstCheckin) < parseInt(selectedCriticalDaysServer)
        && parseInt(selectedPeriod) - calculateDaysPassed(each.serverFirstCheckin) > 0);
    }

    // toggle for server healthy lifecycle
    if (isSwitchOnServerHealthy) {
        sortedFilteredServerLifecycleData = sortedFilteredServerLifecycleData.filter(
            each => parseInt(selectedPeriod) - calculateDaysPassed(each.serverFirstCheckin) > parseInt(selectedCriticalDaysServer));
    }

    // toggle for camera expired lifecycle
    if (isSwitchOnCameraExpired) {
        sortedFilteredServerLifecycleData = sortedFilteredServerLifecycleData.filter(
            each => each.cameras.filter(camera => calculateDaysPassed(camera.cameraFirstCheckin) > selectedPeriod).length > 0);
    }
    
    // toggle for camera critical lifecycle
    if (isSwitchOnCameraCritical) {
        sortedFilteredServerLifecycleData = sortedFilteredServerLifecycleData.filter(
            each => each.cameras.filter(camera => parseInt(selectedPeriod) - calculateDaysPassed(camera.cameraFirstCheckin) < parseInt(selectedCriticalDaysCamera)
            && parseInt(selectedPeriod) - calculateDaysPassed(camera.cameraFirstCheckin) > 0).length > 0);
    }

    // toggle for camera healthy lifecycle
    if (isSwitchOnCameraHealthy) {
        sortedFilteredServerLifecycleData = sortedFilteredServerLifecycleData.filter(
            each => each.cameras.filter(camera => parseInt(selectedPeriod) - calculateDaysPassed(camera.cameraFirstCheckin) > parseInt(selectedCriticalDaysCamera)).length > 0);
    }




     // toggle for no maintenance
     if (isSwitchOnServerNoMaintenance) {
        sortedFilteredServerLifecycleData = sortedFilteredServerLifecycleData.filter(
            each => !each.serverLastMaintenance
        );
    }

    // toggle for expired life expectancy
     if (isSwitchOnServerLifeExpectancy) {
        sortedFilteredServerLifecycleData = sortedFilteredServerLifecycleData.filter(
            each => calculateDaysPassed(each.serverFirstCheckin) >= parseInt(selectedServerLifeExpectancyDays)
        );
    }

    // sort by all columns - alert data
    const sortedAllColumnsFilteredServerLifecycle = [...sortedFilteredServerLifecycleData].sort((a, b) => {
        // for server.serverFirstCheckin
        if (sortConfigServerLifecycle.key === 'operatingDays') {
            return sortConfigServerLifecycle.direction === 'ascending' ? calculateDaysPassed(a.serverFirstCheckin) - calculateDaysPassed(b.serverFirstCheckin) : calculateDaysPassed(b.serverFirstCheckin) - calculateDaysPassed(a.serverFirstCheckin);
        }
        // for server.remainingLifeExpectancy
        else if (sortConfigServerLifecycle.key === 'remainingLifeExpectancy') {
            const remainingLifeExpectancyA = parseInt(selectedServerLifeExpectancyDays) - calculateDaysPassed(a.serverFirstCheckin);
            const remainingLifeExpectancyB = parseInt(selectedServerLifeExpectancyDays) - calculateDaysPassed(b.serverFirstCheckin);
            return sortConfigServerLifecycle.direction === 'ascending' ? remainingLifeExpectancyA - remainingLifeExpectancyB : remainingLifeExpectancyB - remainingLifeExpectancyA;
        } 
        else { // for all other columns
        if (a[sortConfigServerLifecycle.key] < b[sortConfigServerLifecycle.key]) {
            return sortConfigServerLifecycle.direction === 'ascending' ? -1 : 1;
        }

        if (a[sortConfigServerLifecycle.key] > b[sortConfigServerLifecycle.key]) {
        return sortConfigServerLifecycle.direction === 'ascending' ? 1 : -1;
        }
        }

        return 0;
    });

    // Server health data
    const totalServers = filteredServerLifecycleData.length;
    const expiredServers = filteredServerLifecycleData.filter(each => calculateDaysPassed(each.serverFirstCheckin) > parseInt(selectedPeriod)).length;    

    const criticalServers = filteredServerLifecycleData.filter(
        each => ( parseInt(selectedPeriod) - calculateDaysPassed(each.serverFirstCheckin) < parseInt(selectedCriticalDaysServer) && parseInt(selectedPeriod) - calculateDaysPassed(each.serverFirstCheckin) > 0)).length;
    
    const healthyServers = totalServers - expiredServers - criticalServers;
    
    // server health chart data
    const serverData = {
        labels: ['Healthy', 'Critical', 'Expired'],
        datasets: [{
          data: [healthyServers, criticalServers, expiredServers],
          backgroundColor: ['#28a745', '#ffc107', '#dc3545'],
          hoverBackgroundColor: ['#218838', '#e0a800', '#c82333']
        }]
    };

    // calculate average operating days of servers
    const totalOperatingDaysServers = filteredServerLifecycleData.reduce((acc, each) => acc + calculateDaysPassed(each.serverFirstCheckin), 0);
    const averageOperatingDaysServers = parseInt(totalOperatingDaysServers / totalServers);
      

    // camera health data
    const totalCameras = filteredServerLifecycleData.reduce((acc, each) => acc + each.cameras.length, 0);
    const expiredCameras = filteredServerLifecycleData.reduce((acc, each) => acc + each.cameras.filter(camera => calculateDaysPassed(camera.cameraFirstCheckin) > parseInt(selectedPeriod)).length, 0);

   
    const criticalCameras = filteredServerLifecycleData.reduce((acc, each) => acc + each.cameras.filter(
        camera => ( parseInt(selectedPeriod) - calculateDaysPassed(camera.cameraFirstCheckin) < parseInt(selectedCriticalDaysCamera) && parseInt(selectedPeriod) - calculateDaysPassed(camera.cameraFirstCheckin) > 0)).length, 0);

    const healthyCameras = totalCameras - expiredCameras - criticalCameras;

    // camera health chart data
    const cameraData = {
        labels: ['Healthy', 'Critical', 'Expired'],
        datasets: [{
          data: [healthyCameras, criticalCameras, expiredCameras],
          backgroundColor: ['#28a745', '#ffc107', '#dc3545'],
          hoverBackgroundColor: ['#218838', '#e0a800', '#c82333']
        }]
    };  
    
    // calculate average operating days of cameras
    const totalOperatingDaysCameras = filteredServerLifecycleData.reduce((acc, each) => acc + each.cameras.reduce((acc, camera) => acc + calculateDaysPassed(camera.cameraFirstCheckin), 0), 0);
    const averageOperatingDaysCameras = parseInt(totalOperatingDaysCameras / totalCameras);
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isSmallScreen = window.innerWidth < 600;

    const user = localStorage.getItem('email');

    const handleBusinessChange = (e) => {
        setSelectedBusiness(e.target.value);
        setSelectedSite('All');
    }

    const handleSiteChange = (e) => {
        setSelectedSite(e.target.value);
    }

    const handlePeriodchange = (e) => {
        setSelectedPeriod(e.target.value);
    }

    const handleServerCriticalDays = (e) => {
        setSelectedCriticalDaysServer(e.target.value);
    }

    const handleCameraCriticalDays = (e) => {
        setSelectedCriticalDaysCamera(e.target.value);
    }

    const handleServerLifeExpectancy = (e) => {
        setSelectedServerLifeExpectancyDays(e.target.value);
    }

    const handleCameraLifeExpectancy = (e) => {
        setSelectedCameraLifeExpectancyDays(e.target.value);
    }

    // handle toggle server expired lifecycle
    const handleSwitchChange = () => {
        setIsSwitchOnServerExpired(!isSwitchOnServerExpired);
      };


    // handle toggle server expired life expectancy
    const handleSwitchChangeServerLifeExpectancy = () => {
        setIsSwitchOnServerLifeExpectancy(!isSwitchOnServerLifeExpectancy);
      };

    // handle toggle server no mainten
    const handleSwitchChangeServerNoMaintenance = () => {
        setIsSwitchOnServerNoMaintenance(!isSwitchOnServerNoMaintenance);
      };

        
    function calculateDaysPassed(startDateUTC) {
        // Parse the start date as a UTC date
        const start = new Date(startDateUTC);
        // Get the current date in UTC
        const now = new Date();
        // Calculate the difference in milliseconds
        const diffInMilliseconds = now - start;
        // Convert the difference to days
        const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
        return diffInDays;
    }

    useEffect(() => {
        var timer = setInterval(()=>setDate(new Date()), 1000 )
        return function cleanup() {
            clearInterval(timer);
        }
    });

    // fetch businesses tied to the logged user
    useEffect(() => {
       dispatch(listBusinesses(user));   
    }, [dispatch, user]);

   // Memoize the first business
   const firstBusiness = useMemo(() => {
    return businesses.length ? businesses[0] : null;
    }, [businesses]);

    useEffect(() => {
    if (firstBusiness) {
        setSelectedBusiness(firstBusiness);
    }
    }, [firstBusiness]);

    // fetch lifecycledata tied to the selected business
    useEffect(() => {
        if (selectedBusiness) {
            dispatch(listSites({ selectedBusiness, user })); 
        }       
     }, [selectedBusiness, dispatch, user]);

     useEffect(() => {
        if (!user) {
            navigate('/auth/signin');
        }
     }, [navigate, user]);

  

  return (
    <div>
         {!isSmallScreen &&
            <div style={{ marginTop: '-3.5em' }}>
                <SideBar />
            </div>
        }

        <div className='lifecycle-info-container lifecycle-info-container-center'>
            {/* top banner -> welcome + current time */}          
                <div style={{ padding: '.3em', marginBottom: '1rem' }}>
                    <span className='lifecycle-info-welcome-text lifecycle-info-welcome-text-new'>Welcome to your Asset Lifecycle Analytics </span>
                    <br></br>
                    <div className='lifecycle-info-welcome-time'>
                        {date.toLocaleTimeString()}&nbsp;
                        {date.toLocaleDateString()}
                    </div>
                </div>   

        { serverLifecycleData.length ?
            <>

            {
                (!loadingServerLifecycleUpdate || !loading) ?
                <>
             
                {/* select dropdown menu */}
                <div className='selectors-container selectors-container-center'>
                    <div className='selectors-sub-container'>

                        {/* select business dropdown menu */}
                        <label className='lifecycle-info-select-label'>Business:</label>            
                        <select
                        className="lifecycle-info-dropdown"
                        value={selectedBusiness}
                        onChange={handleBusinessChange}
                        >
                        {businesses.map((business, index) => (
                            <option key={index} value={business}>
                            {business}
                            </option>
                        ))}
                        </select>
                        
                        {/* select site dropdown menu */}
                        <label className='lifecycle-info-select-label'>Site:</label>           
                        <select
                        className="lifecycle-info-dropdown"
                        value={selectedSite}
                        onChange={ handleSiteChange }
                        >
                        <option value='All'>All</option>
                        {uniqueSites.map((each, index) => (
                            <option key={index} value={each}>
                            {each}
                            </option>
                        ))}
                        </select>

                        {/* Select Lifecycle  */}                   
                        <label className='lifecycle-info-select-label'>Lifecycle:</label>
                        <select className='lifecycle-info-dropdown' value={ selectedPeriod } onChange={ handlePeriodchange }>
                            <option value="365">365 days</option>
                            <option value="180">180 days</option>
                            <option value="90">90 days</option>
                        </select>
                    </div>
                </div>               

                    
                           
                            
                             {/* charts */}
                            
                            <div className='lifecycle-health-data-container lifecycle-health-data-container-new'>
                                <div>
                                    {/* Select Server critical days before expires */}
                                    <label className='lifecycle-info-select-sub-label'>Critical Days Before Expire:</label>
                                    <select className='lifecycle-info-dropdown' value={ selectedCriticalDaysServer } onChange={ handleServerCriticalDays }>
                                       {
                                            criticalDaysRange.map((each, index) => (
                                                <option key={index} value={each}>{each} days</option>
                                            ))
                                       }                                        
                                    </select>
                                    {/* Select Server Life expectancy  */}
                                    <label className='lifecycle-info-select-sub-label'>Server Life Expectancy:</label>
                                    <select className='lifecycle-info-dropdown' value={ selectedServerLifeExpectancyDays } onChange={ handleServerLifeExpectancy }>
                                        <option value="1095">3 years</option>
                                        <option value="1825">5 years</option>
                                        <option value="1460">4 years</option>                               
                                        <option value="730">2 years</option>
                                        <option value="365">1 years</option>
                                    </select>
                                    <ServerLifecycleChart 
                                        data={serverData}
                                        setIsSwitchOnServerExpired={setIsSwitchOnServerExpired}
                                        setIsSwitchOnServerCritical={setIsSwitchOnServerCritical}
                                        setIsSwitchOnServerHealthy={setIsSwitchOnServerHealthy}
                                        averageOperatingDaysServers={averageOperatingDaysServers}
                                    />
                                </div>
                                    {
                                        !isSmallScreen && totalCameras > 0 &&
                                        <div className='lifecycle-icons-container'>
                                            <img src={heartIcon} alt='' className='lifecycle-heart-image'/>
                                            <img src={timeIcon} alt='' className='lifecycle-time-image'/> 
                                        </div>
                                    }
                                { 
                                    totalCameras > 0 &&
                                
                                    <div>
                                        {/* Select camera critical days before expires */}
                                        <label className='lifecycle-info-select-sub-label lifecycle-info-select-sub-label-small-screen'>Critical Days Before Expire:</label>
                                        <select className='lifecycle-info-dropdown' value={ selectedCriticalDaysCamera } onChange={ handleCameraCriticalDays }>
                                            {
                                                criticalDaysRange.map((each, index) => (
                                                    <option key={index} value={each}>{each} days</option>
                                                ))
                                            }
                                        </select>
                                        {/* Camera Life expectancy  */}
                                        <label className='lifecycle-info-select-sub-label'>Camera Life Expectancy:</label>
                                        <select className='lifecycle-info-dropdown' value={ selectedCameraLifeExpectancyDays } onChange={ handleCameraLifeExpectancy }>
                                            <option value="1825">5 years</option>
                                            <option value="1460">4 years</option>
                                            <option value="1095">3 years</option>
                                            <option value="730">2 years</option>
                                            <option value="365">1 years</option>
                                        </select>
                                    
                                        <CameraLifecycleChart 
                                            data={cameraData}
                                            setIsSwitchOnCameraExpired={setIsSwitchOnCameraExpired}
                                            setIsSwitchOnCameraCritical={setIsSwitchOnCameraCritical}
                                            setIsSwitchOnCameraHealthy={setIsSwitchOnCameraHealthy}
                                            averageOperatingDaysCameras={averageOperatingDaysCameras}
                                        />                                    
                                    </div> 
                                }                  
                            </div>

                            <div className='toggle-swich-container toggle-swich-container-new'>

                               <div className='toggle-swich-expired-lifeExpectancy'>
                                    <Form>
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            label="No Maintenance"
                                            checked={isSwitchOnServerNoMaintenance}
                                            onChange={handleSwitchChangeServerNoMaintenance}
                                        />
                                    </Form>
                               </div>
                            </div>

                
                            <div className="table-wrapper table-wrapper-new">
                                <table className='table-wrapper-new-content'>
                                    <thead >
                                        <tr>
                                            <th onClick={() => handleSortServerLifecycle('serverName')}>
                                                Server {renderSortIconServerLifecycle('serverName')}
                                            </th>
                                            <th onClick={() => handleSortServerLifecycle('operatingDays')}>
                                                Total Operating Days {renderSortIconServerLifecycle('operatingDays')}
                                            </th>
                                            <th onClick={() => handleSortServerLifecycle('daysSinceLastMaintenance')}>
                                                Total Days Since Last Maintenance {renderSortIconServerLifecycle('daysSinceLastMaintenance')}
                                            </th>
                                            <th onClick={() => handleSortServerLifecycle('remainingLifeExpectancy')}>
                                                Total Remaining Life Expectancy Days {renderSortIconServerLifecycle('remainingLifeExpectancy')}
                                            </th>
                                            <th>Update Maintenance Event</th>
                                            <th onClick={() => handleSortServerLifecycle('totalCameras')}>
                                                Total Cameras {renderSortIconServerLifecycle('totalCameras')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            sortedAllColumnsFilteredServerLifecycle.map(eachServer => (
                                                <ServerLifecycle
                                                    key={eachServer.serverName}
                                                    server={eachServer}
                                                    selectedPeriod={parseInt(selectedPeriod)}
                                                    loading={loading}
                                                    selectedServerLifeExpectancyDays={selectedServerLifeExpectancyDays}
                                                    selectedCameraLifeExpectancyDays={selectedCameraLifeExpectancyDays}
                                                    selectedCriticalDaysServer={selectedCriticalDaysServer}
                                                    selectedCriticalDaysCamera={selectedCriticalDaysCamera}
                                                />
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </>
                        : 
                        <div style={{ marginTop: '20%', textAlign: 'center' }}>
                            <BeatLoader size={14} color="gray"/>
                        </div>
                    }
                   
               
                   </> :

                    loading ?                   
                        <div className='loading-refresh-lifecycle'>
                            <RingLoader size={ !isSmallScreen ? 300 : 200} color="#F49523"/>
                        </div>
                    :
                    <div className='lifecycle-no-record-message'>
                        No record found for the selected business {selectedBusiness} !
                    </div>
                }
            </div>         
        
            <div className='footer-lifecycle-container'>
                <Footer />
            </div>

        </div>
  )
}

export default Lifecycle