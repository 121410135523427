import * as Actions from '../../constant/ActionTypes';

const licenseUsersReducers = (licenseUsers=[], action) => {
    switch(action.type) {
        case Actions.LIST_LICENSE_USERS:    
            return action.payload;

        default:
            return licenseUsers;
    }
}

export default licenseUsersReducers;