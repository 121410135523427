import * as Actions from '../constant/ActionTypes';

const deviceReducers = (devices=[], action) => {
    switch(action.type) {
        case Actions.LIST_DEVICES:
            return action.payload;

        default :
        return devices;
    }
}
export default deviceReducers;