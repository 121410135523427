// import libraries
import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';

// import local files
import './superAdmin.css';
import Footer from '../Footer';
import SideBar from '../SideBar';
import Licenses from './licenses/Licneses';
import Users from './users/Users';
import ApprovedSites from './approvedSites/ApprovedSites';

const SuperAdmin = () => {
    const [currentView, setCurrentView] = useState('Licenses');
    const [key, setKey] = useState(0);

    const isSmallScreen = window.innerWidth < 600;


  const handleLinkClick = (view) => {
    setCurrentView(view);
    setKey(prevKey => prevKey + 1);  // Change the key to force a re-render
  };


  console.log(`currentView = ${currentView}`);


  return (
   <>   
        {/* hide sidebar for the small screen */}
        {
            !isSmallScreen && <div style={{ marginTop: '-3.3em' }}>
            <SideBar />
            </div>
        }

    <div className='superAdmin-container' >
      <Navbar className='super-admin-nav-container' expand="lg">     
        <Navbar.Toggle aria-controls="basic-navbar-nav" className='super-admin-nav-toggle' />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav >
            <Button 
              onClick={() => handleLinkClick('Licenses')} 
              className='super-admin-nav-link'
              variant="link"
            >
              Licenses
            </Button>

            {/* <Button 
              onClick={() => handleLinkClick('Users')} 
              className='super-admin-nav-link'
              variant="link"
            >
              Users
            </Button>
            <Button 
              onClick={() => handleLinkClick('ApprovedSites')} 
              className='super-admin-nav-link'
              variant="link"
            >
              Approved Sites
            </Button> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <div className='content'>
        {currentView === 'Licenses' && <Licenses key={key}/>}
        {currentView === 'Users' && <Users key={key}/>}
        {currentView === 'ApprovedSites' && <ApprovedSites key={key}/>}
      </div>
    </div> 

       <div className='superAdmin-footer-container'>
        <Footer />
       </div>
   </>
  
  )
}

export default SuperAdmin