import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import '../../../src/styles/healthInfo.css';
import CustomTooltip from './CustomTooltip';

ChartJS.register(ArcElement, Tooltip, Legend);


function HealthCharts({ 
    data,
    totalOnlineServers,
    totalOfflineServers,
    totalOnlineDevices,
    totalOfflineDevices,
    totalServers,
    totalDevices 
  }) 
  {

  const options = {
    plugins: {
      legend: {
        labels: {
          color: '#ccc', 
        },        
      },    
     
    },
    rotation: -90,
    circumference: 180,
    cutout: 105, // Use a numerical value without the percentage symbol
    maintainAspectRatio: true,
    responsive: true, 
  };

  // set color for offline servers count at doughnut
  let totalOfflineServersColor = '#e9426C';
  if (!totalOfflineServers) {
    totalOfflineServersColor = '#32DE84';
  }

  // set color for offline cameras count at doughnut
  let totalOfflineDevicesColor = '#e9426C';
  if (!totalOfflineDevices) {
    totalOfflineDevicesColor = '#32DE84';
  }

  return (
    <div className='chart-container' >
      <div >
        <span className='activity-label'>
          Server Activity     
        </span>
        <div style={{ position: 'relative', textAlign: 'center' }}>
          <Doughnut data={data.servers} options={options} />
          <div className='chart-values'>           
              <span className='chart-offline-number' >
                <CustomTooltip text="Total Servers">
                  <span style={{ color: 'white' }}>{totalServers}</span>
                </CustomTooltip> /{' '}
                <CustomTooltip text="Offline Servers">
                  <span style={{ color: totalOfflineServersColor }}>{totalOfflineServers}</span>
                </CustomTooltip>
              </span>
             
          </div>
        </div>
      </div>
    
      <div >  
      <span className='activity-label'>
        Camera Activity
       </span>
      <div style={{ position: 'relative', textAlign: 'center' }}>   
        <Doughnut  data={data.devices} options={options} />
        <div className='chart-values'>
          <span className='chart-offline-number' >
            <CustomTooltip text="Total Cameras">
              <span style={{ color: 'white' }}>{totalDevices}</span>
            </CustomTooltip> /{' '}
            <CustomTooltip text="Offline Cameras">
            <span style={{ color: totalOfflineDevicesColor }}>{totalOfflineDevices}</span>
            </CustomTooltip>
          </span>
        </div>
      </div>
      </div>

    </div>
  );
}

export default HealthCharts;
