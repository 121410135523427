import React, { useEffect, useState } from 'react';
import {Modal, Button} from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { BeatLoader } from 'react-spinners';
import { useToasts } from 'react-toast-notifications';

import CameraLifecycle from './CameraLifecycle';
import GradedBar from './serverGradedBar/ServerGradedBar';
import ServerLifeExpectancyBar from './serverGradedBar/ServerLifeExpectancyBar';
import ServerLastMaintenanceBar from './serverGradedBar/ServerLastMaintenance';

import { updateServerLifecycle } from '../../redux/actions/updateServerLifecycleAction';


const viewIcon = require('../../images/viewIcon.png');
const alertIcon = require('../../images/alertIcon.png');
const restartIcon = require('../../images/restart.png');

const ServerLifecycle = ({ server, selectedPeriod, selectedServerLifeExpectancyDays, selectedCameraLifeExpectancyDays, loading, selectedCriticalDaysServer, selectedCriticalDaysCamera }) => {

    const [showCameraModal, setShowCameraModal] = useState(false);
    const [showWarningModal, setShowWarningaModal] = useState(false);
    const [action, setAction] = useState(false);
    
    const updateServerLifecyclResponse = useSelector(state => state.updateServerLifecycle);

    const user = localStorage.getItem('email');   

    const { addToast } = useToasts();

    let barColor = 'green';

    // set color for expited servers
    if (calculateDaysPassed(server.serverFirstCheckin) > selectedPeriod) {
      barColor = 'red';
    }

    // set color for critical servers
    if (parseInt(selectedPeriod) - calculateDaysPassed(server.serverFirstCheckin) < parseInt(selectedCriticalDaysServer)
      && parseInt(selectedPeriod) - calculateDaysPassed(server.serverFirstCheckin) > 0) {
        barColor = 'orange';
    }

    const serverOperatingDays = calculateDaysPassed(server.serverFirstCheckin);

    const serverRemainingLifeExpectancyDays = selectedServerLifeExpectancyDays - serverOperatingDays;
    
    let  serverLastMaintenance = calculateDaysPassed(server.serverLastMaintenance);
    if (!server.serverLastMaintenance) {
      serverLastMaintenance = 'No maintenance performed';
    }

    const BeatLoaderSize = 10;    

    // servers lifecycle bar
    const minLifecycleDays = 0;
    const maxLifecycleDays = selectedPeriod;
    const currentLifecycleDays = calculateDaysPassed(server.serverFirstCheckin);

    // servers life expectancy bar
    const minServerLifeExpectancy = 0;
    const maxServerLifeExpectancy = selectedServerLifeExpectancyDays;
    const currentServerLifeExpectancy = serverRemainingLifeExpectancyDays;

    // servers last maintenance bar
    const minServerLastMaintenance = 0;
    const maxServerLastMaintenance = selectedPeriod;
    let currentServerLastMaintenance = serverLastMaintenance;
    if (serverLastMaintenance === 'No maintenance performed') {
      currentServerLastMaintenance = selectedPeriod;
    }

    const dispatch = useDispatch();

     // Camera Modal control
     const openCameraModal = () => {
        setShowCameraModal(true);
    }
    const closeCameraModal = () => {
        setShowCameraModal(false);
    }

     // Warming Modal
     const openWarningModal = () => {
      if (serverLastMaintenance === 0) {
        const errorMsg = `Maintenance event of server ${server.serverName} was already updated today.`;
        addToast(errorMsg, {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 9000  
        });
        return;
      }
      setShowWarningaModal(true);
    }

    const closeWarningModal = () => {
        setShowWarningaModal(false);
    }
    
    function calculateDaysPassed(startDateUTC) {
        // Parse the start date as a UTC date
        const start = new Date(startDateUTC);
        // Get the current date in UTC
        const now = new Date();
        // Calculate the difference in milliseconds
        const diffInMilliseconds = now - start;
        // Convert the difference to days
        const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
        return diffInDays;
    }

    const handleResetOperatingTime = () => {
      setAction(true);
      const payload = { hwid: server.hwid, server_name:  server.serverName, user: user}
      dispatch(updateServerLifecycle(payload));
      closeWarningModal();
    }

    useEffect(() => {
      if (action && updateServerLifecyclResponse.code === 200) {
        const successMsg =`Maintenance event of server ${server.serverName} is updated.`;
        addToast(successMsg, {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 9000  
        });
        setAction(false);
        
      }
      if (action && updateServerLifecyclResponse.code === 500) {
        const errorMsg = "Internal server error. Please try after some time.";
        addToast(errorMsg, {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 9000  
        });
        setAction(false);
      }     
    }, [updateServerLifecyclResponse.code, addToast, action]);

  return (
    <>
    
    <tr>

        <td>
            {!loading ? 
              server.serverName :
              <span style={{ marginTop: '10%', textAlign: 'center' }}>
                <BeatLoader size={BeatLoaderSize} color="gray"/>
              </span>
            }
        </td>

        <td>
           {!loading ?
              <div style={{ display: 'flex' }}>               
                <div style={{ width: '2.5em' }}>{serverOperatingDays}</div>
                <GradedBar barColor={barColor} />
                {
                  calculateDaysPassed(server.serverFirstCheckin) > selectedPeriod ?
                  <div>
                    <img src={alertIcon} alt='Alert' style={{ height: '1.6em', marginLeft: '0.5em' }}/>
                  </div> : null                
                }              
              </div> :
              <span style={{ marginTop: '10%', textAlign: 'center' }}>
              <BeatLoader size={BeatLoaderSize} color="gray"/>
            </span>
           }
        </td>

        <td>
            { !loading ?
            <div style={{ display: 'flex' }}>
              {/* <div style={{ width: '2.5em'}}>{server.serverLastMaintenance ? serverLastMaintenance : <span title='No Maintenance Performed'>&#x1F6AB;</span>}</div> */}
              <div >{serverLastMaintenance}</div>
              {/* <ServerLastMaintenanceBar value={currentServerLastMaintenance} min={minServerLastMaintenance} max={maxServerLastMaintenance} />
              {
                !server.serverLastMaintenance ?
                <div>
                  <img src={alertIcon} alt='Alert' style={{ height: '1.6em', marginLeft: '0.5em' }}/>
                </div> : null
              } */}
            </div> :
            <span style={{ marginTop: '10%', textAlign: 'center' }}>
              <BeatLoader size={BeatLoaderSize} color="gray"/>
            </span>
            }
        </td>

        <td>{ !loading ? 
            <div style={{ display: 'flex' }}>
              <div style={{ width: '2.5em' }}>{serverRemainingLifeExpectancyDays}</div>
              <ServerLifeExpectancyBar value={currentServerLifeExpectancy} min={minServerLifeExpectancy} max={maxServerLifeExpectancy} />
              {
                calculateDaysPassed(server.serverFirstCheckin) >= parseInt(selectedServerLifeExpectancyDays) ?
                <div>
                  <img src={alertIcon} alt='Alert' style={{ height: '1.6em', marginLeft: '0.5em' }}/>
                </div> : null
              }
            </div> :
            <span style={{ marginTop: '10%', textAlign: 'center' }}>
              <BeatLoader size={BeatLoaderSize} color="gray"/>
            </span>
            }
        </td>

        <td>
          { 
            !loading ?
            <button className='reset-btn' onClick={ openWarningModal }>
                <img src={restartIcon} alt='Reset' style={{ height: '1.5em' }}/>
            </button>
            :
         
            <span>
              <BeatLoader size={BeatLoaderSize} color="gray"/>
            </span>
            
          }         
        </td>

        <td>
           { !loading ? 
            <div style={{ display: 'flex' }}>
              <div style={{ width: '2em' }}>{server.totalCameras}</div>
              <button className='view-icon-btn' data-tooltip="View details" onClick={openCameraModal}>
                  <img src={viewIcon} alt='view' className='view-icon' style={{ height: '2em' }}/>
              </button>              
            </div> :
             <div>
             <BeatLoader size={BeatLoaderSize} color="gray"/>
           </div>
           }
        </td>

    </tr>

     {/* modal to view cameras lifecycle data modal*/}

     <Modal size='lg' show={showCameraModal} onHide={closeCameraModal} centered animation>
        <Modal.Header closeButton style={{ background: '#202841' }} closeVariant='white'>
          <Modal.Title className='cameras-modal-title'>Cameras Lifecycle for server: <span className='cameras-modal-title-servername'>{server.serverName}</span></Modal.Title>
        </Modal.Header>

        <Modal.Body style={{background: '#202841', paddingRight:0 }}>
          <div>
            <CameraLifecycle 
              cameras={server.cameras}
              selectedPeriod={selectedPeriod}
              serverName={server.serverName}
              selectedCameraLifeExpectancyDays={selectedCameraLifeExpectancyDays}
              selectedCriticalDaysCamera={selectedCriticalDaysCamera}
              />
          </div>
        </Modal.Body>
      </Modal>  

        {/* Warning Modal*/}

     <Modal size='sm' show={showWarningModal} onHide={closeWarningModal}  >
        <Modal.Header closeButton style={{ background: '#202841' }} closeVariant='white'>
          <Modal.Title className='cameras-modal-title'>Are you sure you want to confirm maintenance event for server <span style={{ color: 'orange' }}>{server.serverName}</span></Modal.Title>
        </Modal.Header>

        <Modal.Body style={{background: '#202841', paddingRight:0 }}>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button variant="danger" size='sm' onClick={handleResetOperatingTime} style={{ width: '5em' }}>Yes</Button>
            <Button variant="secondary" size='sm' onClick={closeWarningModal} style={{ width: '5em' }}>Cancel</Button>
          </div>
        </Modal.Body>
      </Modal>       
    </>
  )
}

export default ServerLifecycle