import * as Actions from '../../constant/ActionTypes';

const licenseCountReducers = (count=1, action) => {
    switch(action.type) {
        case Actions.COUNT:    
            return action.payload;      
            
        default:
            return count;
    }
}

export default licenseCountReducers;