import * as Actions from '../constant/ActionTypes';

const listBusinessesReducers = (businesses=[], action) => {
    switch(action.type) {
        case Actions.LIST_BUSINESSES:           
            return action.payload;

        default :
        return businesses;
    }
}
export default listBusinessesReducers;