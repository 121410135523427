import * as Actions from '../constant/ActionTypes';

const listSitesReducers = (sites=[], action) => {
    switch(action.type) {
        case Actions.LIST_SITES:           
            return action.payload;

        case Actions.UPDATE_SERVER_LIFE_CYCLE:
            return sites.map(
                each => each.serverName === action.payload.serverName ? {...each, serverLastMaintenance: action.payload.serverLastMaintenance} : each
            );

            case Actions.UPDATE_CAMERA_LIFE_CYCLE:
                return sites.map(site => {
                    const updatedCameras = site.cameras.map(camera =>
                        camera.cameraName === action.payload.cameraName
                            ? { ...camera, cameraLastMaintenance: action.payload.cameraLastMaintenance }
                            : camera
                    );
                    return { ...site, cameras: updatedCameras };
                    }
                );

        default :
            return sites;
    }
}
export default listSitesReducers;