import { combineReducers } from 'redux';
import servers from './ServerReducers';
import devices from './DeviceReducers';
import ack from './ackReducers' ;
import acked from './ackedReducers';
import storage from './storageReducers';
import report from './reportReducers';
import checkUser from './checkUserRegisterReducer';
import user from './addUserReducers' ;
import optemail from './optEmailReducer';
import optemailCamera from './optEmailCameraReducer';
import businessTokenMapping from './businessTokenMappingReducers';
import fetchUserResponse from './fetchUserReducers';
import listBusinesses from './listBusinessesReducers';
import listSites from './listSitesReducers';
import loading from './loadingReducers/loadingReducers';
import loadingServerLifecycleUpdate from './loadingReducers/loadingServerLifecycleUpdate';
import loadingCameraLifecycleUpdate from './loadingReducers/loadingCameraLifecycleUpdate';
import updateServerLifecycle from './updateServerLifecycleReducers';
import updateCameraLifecycle from './updateCameraLifecycleReducers';

// licnese related
import licenses from './licnesesReducers/licenseReducers';
import pages from './licnesesReducers/licensePagesReducers';
import count from './licnesesReducers/licenseCountReducers';
import licenseUsers from './licnesesReducers/licenseUsersReducers';

import selectedBusinessReducer from './selectedBusinessReducers';

export default combineReducers({
    servers,
    devices,
    ack,
    acked,
    storage,
    report,
    checkUser,
    user,
    optemail,
    optemailCamera,
    businessTokenMapping,
    fetchUserResponse,
    listBusinesses,
    listSites,
    loading,
    loadingServerLifecycleUpdate,
    updateServerLifecycle,
    loadingCameraLifecycleUpdate,
    updateCameraLifecycle,
    selectedBusinessReducer,
    licenses,
    pages,
    count,
    licenseUsers
 });