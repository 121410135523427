

const AdditionalInfo = ({ server }) => {
  return (
    server?.additionalInfo?.map((eachInfo, index) => (
        <tr key={index}>
            <td>{eachInfo.site}</td>            
            <td>{eachInfo.info_type}</td>
            <td>{eachInfo.info_label}</td>
            <td>{eachInfo.info_status}</td>
        </tr>
    )    
    )
  )
}

export default AdditionalInfo