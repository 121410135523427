import * as Actions from "../constant/ActionTypes";
import * as api from '../../api';

export const listSites = (payloadData) => async (dispatch) => {
    try {
        dispatch({
            type: Actions.LOADING,
            payload: true
           })

        const response  = await api.listSites(payloadData);
        const data = response.data;
        
        if (response.status === 200) {
           dispatch({
            type: Actions.LOADING,
            payload: false
           })
        }

        dispatch({
            type: Actions.LIST_SITES,
            payload: data
        })
    } catch (error) {
        console.log(error);
        dispatch({
            type: Actions.LOADING,
            payload: false
           })
    }
}