export const webURL = 'https://www.artoflogic.ai';

export const card1Text = 'With over 80 objects in our library, we can detect their presence. When combined with our BI Dashboard you can track asset utilisation and much more.';
export const card2Text = 'Unparalleled People Count, Dwell and Queue Detect people with or without facial recognition';
export const card3Text = 'Vehicle & Parking: LPR Number Plate Recognition with Colour, Make and Model';

export const policyNames = [
    '1-Channel Samurai Count',
    '2-Channel Samurai Count',
    '4-Channel Samurai Count',
    '1-Channel Samurai Detect',
    '2-Channel Samurai Detect',
    '4-Channel Samurai Detect',
    '6-Channel Health Monitoring',
    '50-Channel Health Monitoring',
    '100-Channel Health Monitoring',
    '4-Channel Parking Management',
    '2-Channel Parking Management',
    '4-Channel Corsight',
    '2-channel Object',
    '50-Channel Health Monitoring Demo',
    '4-Channel Samurai Count Demo',
    '4-Channel Samurai Detect Demo'
  ];

  export const reportFooter = '© 2023 Art of Logic Pty Ltd. All rights reserved.';