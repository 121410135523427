import '../../../src/styles/healthInfo.css';

import Server from './Server';

const UnAckAlerts = ({ unAckAlerts }) => {
  return (
    <div className='table-container-unackalerts'>        
    <table>
        <thead >
            <tr>
            <th >Site Name</th>
            <th >SI Name</th>
            <th >Server Name</th>
            <th >Server Status</th>
            <th>Activity Time</th>
            <th >Server Offline Since</th>
            <th >Cameras - Total / Offline </th>
                
            </tr>
        </thead>
        <tbody>
            {unAckAlerts.map((server, index) => (
                   <Server 
                   key={index}
                   server={server} 
                   isAlert={true}
                   isUnAck={true}
                   />
            ))}
        </tbody>
    </table>
</div>
  )
}

export default UnAckAlerts