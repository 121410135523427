import React, { useState, useEffect } from 'react'; 
import { BeatLoader } from 'react-spinners';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';


import { suspendLicenses,  reinstateLicenses, deleteLicenses} from '../../../redux/actions/licenses/licenseActions';
import LicenseEditForm from './LicenseEditForm';
import './licenses.css';
import CustomTooltipName from '../../tooltips/CustomTooltipLicenseName';


const License = ({ license }) => {
  const [modalOpenEdit, setModalOpenEdit] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const[suspendAction, setSuspendAction] = useState(false);
  const[reinstateAction, setReinstateAction] = useState(false);
  const [selectedActionLicenseKey, setSelectedActionLicenseKey] = useState('');

  const { licenses, loading, error } = useSelector(state => state.licenses);

  const isSmallScreen = window.innerWidth < 700;

  const signedInUser = localStorage.getItem('email');

  //Modal toggle settings for change policy tag
  const handleModalOpenEdit = () => { setModalOpenEdit(true) };
  const handleModalCloseEdit = () => { setModalOpenEdit(false) };

  const dispatch = useDispatch();

  const handleSuspend = () => {
    setSelectedActionLicenseKey(license.license_key);
    dispatch(suspendLicenses({ key: license.license_key, eventCreator: signedInUser }));
  }

  const handleReinstate = () => {
    setSelectedActionLicenseKey(license.license_key);
    dispatch(reinstateLicenses({ key: license.license_key, eventCreator: signedInUser }));
  }

  const handleDelete = () => {
    setShowConfirmation(true);
  } 

  const confirmDelete = () => {
    setSelectedActionLicenseKey(license.license_key);
    // Perform delete action
    const licenseKey = license.license_key ? license.license_key : 'blank';
    dispatch(deleteLicenses({ key: licenseKey, eventCreator: signedInUser }));
    // Hide the confirmation dialog after deletion
    setShowConfirmation(false);
  };

  const cancelDelete = () => {
    // Hide the confirmation dialog if cancel is clicked
    setShowConfirmation(false);
  };

  let statusColor = 'white';
  if (license.status === 'SUSPENDED') {
    statusColor = '#ffc107';
  }
  if (license.status === 'ACTIVE') {
    statusColor = '#198754';
  }


  useEffect(() => {
    if (!loading.deleteLicense) {
      setSelectedActionLicenseKey('');
    }
  }, [loading.deleteLicense]);

  useEffect(() => {
    if (!loading.suspendLicense) {
      setSelectedActionLicenseKey('');
    }
  }, [loading.suspendLicense]);

  useEffect(() => {
    if (!loading.reinstateLicense) {
      setSelectedActionLicenseKey('');
    }
  }, [loading.reinstateLicense]);

  const toolTipText = `Policy: ${license.policy}`;

   // convert utc time into human readable local time
   const utcStrToLocal = (utcTimestamp) => {
    // Create a Date object using the UTC timestamp
    const utcDate = new Date(utcTimestamp);

    // Get the local time components
    const localYear = utcDate.getFullYear();
    const localMonth = utcDate.getMonth() + 1; // Months are zero-based
    const localDay = utcDate.getDate();
    const localHours = utcDate.getHours();
    const localMinutes = utcDate.getMinutes();
    const localSeconds = utcDate.getSeconds();

    // Create a new Date object with the local time components
    const localDate = new Date(localYear, localMonth - 1, localDay, localHours, localMinutes, localSeconds);

    // Format the local time as needed
    const formattedLocalTime = localDate.toLocaleString();

    return formattedLocalTime
}


  return (
    <>
    <tr>
        <td>{license.business}</td>

        <td>{license.license_key}</td>

        <td>
          <CustomTooltipName text={ toolTipText }>
            <span className='info-icon'></span>
          </CustomTooltipName>          
          {license.name}
        </td>

        <td style={{ color: statusColor }}>{license.status}</td>

        <td>{ license.user }</td>
        
        <td>{utcStrToLocal(license.created)}</td>

        <td>{utcStrToLocal(license.updated)}</td>

        <td>
          <div className='license-actions'>
              <div>
                <Button id='license-action-btn' variant='warning' disabled={license.status === 'SUSPENDED'} onClick={handleSuspend}>Suspend</Button>
                {
                 selectedActionLicenseKey === license.license_key && loading.suspendLicense && 
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ marginTop: '1em' }}>
                      <BeatLoader size={5} color="#36d7b7"/>
                    </div>
                  </div>
                }
                </div>
              <div>
                <Button id='license-action-btn' variant='success' disabled={license.status === 'ACTIVE'} onClick={handleReinstate}>Reinstate</Button>
                {
                  selectedActionLicenseKey === license.license_key && loading.reinstateLicense &&  
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ marginTop: '1em' }}>
                      <BeatLoader size={5} color="#36d7b7"/>
                    </div>
                  </div>
                }
              </div>

              {/* <div>
                <Button id='license-action-btn' onClick={handleModalOpenEdit}>Edit_Policy</Button>
              </div> */}

              <div>
                <Button id='license-action-btn' variant='danger' onClick={handleDelete}>
                  Delete
                </Button>
                
                { 
                  selectedActionLicenseKey === license.license_key && loading.deleteLicense && 
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ marginTop: '0.3em' }}>
                      <BeatLoader size={5} color="#36d7b7"/>
                    </div>
                  </div>
                }
              </div>

            {/* delete license Modal */}

            <Modal show={showConfirmation} onHide={cancelDelete}>
              <Modal.Header closeButton className='delete-modal-header'>
                <Modal.Title>
                  <span className='delete-modal-title'>
                    Confirm Delete
                  </span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className='delete-modal-body'>
                <div style={{ marginBottom: '1em' }}>
                  Are you sure you want to delete the following license ?
                  <div>
                    {license.name}
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                 {
                   <Button variant="danger" size='sm' onClick={confirmDelete}>Delete</Button>
                 }
                  <Button variant="secondary" size='sm' onClick={cancelDelete}>Cancel</Button>
                </div>
              </Modal.Body>           
            </Modal>
          </div>           
        </td>
    </tr>

    {/* change policy modal */}
    <Modal show={modalOpenEdit} onHide={handleModalCloseEdit} className="me-2">
                    <Modal.Header closeButton>
                        <Modal.Title style={{ fontSize: '0.95rem' }}>Change Policy to this license with key: <span style={{ fontSize: '0.84rem', color: 'gray' }}>{license.key}</span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <LicenseEditForm  closeLicenseEdit={handleModalCloseEdit} policy={license.policy} licenseKey={license.key} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleModalCloseEdit} >
                            Close
                        </Button>                   
                    </Modal.Footer>
      </Modal>
    </>
  )
}

export default License