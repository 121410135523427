import * as Actions from '../constant/ActionTypes';

const ackReducers = (user={responseCode: 500}, action) => {
    switch(action.type) {
        case Actions.ADD_USER:
            return action.payload;

        default :
        return user;
    }
}
export default ackReducers;