import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Mute from '../../images/Mute.png';
import { optEmailCamera } from '../../redux/actions/optEmailCamera';
import CustomTooltipDevice from '../tooltips/CustomTooltipDevice';


const Device = ({ index, device, user, serverStatus, serverOfflineSince, emailOptAllCameras }) => {  
    const [checkOptOutEmail, setCheckOptOutEmail] = useState(false); 
   
   // convert utc time into human readable local time
   const utcStrToLocal = (utcTimestamp) => {
        // Create a Date object using the UTC timestamp
        const utcDate = new Date(utcTimestamp);

        // Get the local time components
        const localYear = utcDate.getFullYear();
        const localMonth = utcDate.getMonth() + 1; // Months are zero-based
        const localDay = utcDate.getDate();
        const localHours = utcDate.getHours();
        const localMinutes = utcDate.getMinutes();
        const localSeconds = utcDate.getSeconds();

        // Create a new Date object with the local time components
        const localDate = new Date(localYear, localMonth - 1, localDay, localHours, localMinutes, localSeconds);

        // Format the local time as needed
        const formattedLocalTime = localDate.toLocaleString();

        return formattedLocalTime
    }

    const dispatch = useDispatch();

    let toolTipText = "Mute email notifications";
    if (checkOptOutEmail) {
        toolTipText = "Unmute email notifications";
    }

    let confirmMsg = "Are you sure you want to mute notifications for this camera?";
    if (checkOptOutEmail) {
        confirmMsg = "Are you sure you want to unmute notifications for this camera?";
    }


    // dispatch email opt-out submission to the backend
    const handleOptEmail = (e, serverName, cameraId, cameraName) => {
        var confirmation = window.confirm(confirmMsg);

        if (confirmation) {
            setCheckOptOutEmail(e.target.checked);
            if (e.target.checked) {
            dispatch(optEmailCamera({optEmail: 0, user: user, serverName, cameraId, cameraName}));
            }
            else {
            dispatch(optEmailCamera({optEmail: 1, user: user, serverName, cameraId, cameraName}));
            }
        }
    };

    useEffect(() => {
        setCheckOptOutEmail(device?.emailOptOut);
    }, [device?.emailOptOut]);

  return (
    <>
        <tr key={index}>
            <td>{device?.cameraName}</td>
            <td>{device?.serverName}</td>
            <td style={{ color: serverStatus === 'Offline' || device?.cameraStatus === 'Offline' ? '#EF5B5B' : '#32de84'}}>
                {serverStatus === 'Offline' ? 'Offline' : device?.cameraStatus}
            </td>
            <td>{serverOfflineSince !== '-' ? serverOfflineSince :
                    (device?.cameraOfflineSince === 'N/A' ? 'N/A' : utcStrToLocal(device?.cameraOfflineSince))
                    }
            </td>
            <td>
                <CustomTooltipDevice text={ toolTipText } >
                    <img alt='Opt-Out Email Notifications' style={{ height: '1rem', marginRight: '0.5rem', marginTop: '-0.4rem' }} src={Mute}></img>
                    <input 
                        type='checkbox'
                        checked={checkOptOutEmail}
                        onChange={(e) => handleOptEmail(e, device?.serverName, device?.cameraId, device?.cameraName)}>                                    
                    </input>
                </CustomTooltipDevice>
            </td>
        </tr>
    </>
  )
}

export default Device