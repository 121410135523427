import * as Actions from "../constant/ActionTypes";
import * as api from '../../api';

export const listServers = (user) => async (dispatch) => {
    try {
        localStorage.setItem('selectedServerName', '');
        const { data } = await api.listServers({user: user});
        dispatch({
            type: Actions.LIST_SERVERS,
            payload: data
        })
    } catch (error) {
        console.log(error);
    }
}








