import React, { useState } from 'react';

import { useSelector } from 'react-redux';


import '../../../src/styles/healthInfo.css';
import Device from './Device';


const Devices = ({ serverOfflineSince, devices, serverStatus }) => { 

    const [filteredDevices, setFilteredDevices] = useState(devices);
        
    const user = localStorage.getItem('email');
    
    const emailOptResponse = useSelector(state => state.optemailCamera);

    const handleSearchedCamera = (e) => {
        // filter the devices based on the camera name
        const filteredDevices = devices.filter(device => device?.cameraName.toLowerCase().includes(e.target.value.toLowerCase()));
        // set the filtered devices to the devices state
        setFilteredDevices(filteredDevices);
    }
     
  return (
    <div className='table-container-device' style={{ fontSize: '.85em' }}>
        {/* add a search bar here to filter the devices given camera name */}
        <div className='search-bar'>
            <input type='text' placeholder='Search camera name' onChange={ handleSearchedCamera }/>
        </div>

        <table id='server-table'>
            <thead>
                <tr>
                    <th>Camera name</th>
                    <th >Server name</th>
                    <th >Status</th>
                    <th >Offline since</th>
                    <th >Mute notifications</th>
                </tr>
            </thead>
            <tbody>
                {filteredDevices.map((device, index) => (
                 
                    <Device 
                    index={index}
                        device={device}
                        user={user} 
                        serverStatus={serverStatus} 
                        serverOfflineSince={serverOfflineSince} 
                        emailOptAllCameras={emailOptResponse}
                    />
                    
                ))}
            </tbody>
        </table>
    </div>
  )
}

export default Devices