import * as Actions from '../constant/ActionTypes';

const checkUserReducers = (res={response: 'false'}, action) => {
    switch(action.type) {
        case Actions.IS_USER_REGISTERED:
            localStorage.setItem('fetch_user_check', 'true');
            return action.payload;

        default :
        localStorage.setItem('fetch_user_check', 'false');
        return res;
    }
}
export default checkUserReducers;