import * as Actions from '../constant/ActionTypes';

const storageReducers = (storage=[], action) => {
    switch(action.type) {
        case Actions.STORAGE:
            return action.payload;

        default :
        return storage;
    }
}
export default storageReducers;