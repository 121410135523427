import * as Actions from '../../constant/ActionTypes';

const loadingReducers = (response=false, action) => {
    switch(action.type) {
        case Actions.LOADING:           
            return action.payload;

        default :
        return response;
    }
}
export default loadingReducers;