import React, { useEffect, useState, useMemo } from 'react';
import {Button, Modal} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

// error notification box
import { useToasts } from 'react-toast-notifications';

import { useDispatch, useSelector } from 'react-redux';
import { BeatLoader } from 'react-spinners';
import { fetchUser } from '../../redux/actions/fetchUser';
import SideBar from '../SideBar';
import '../../../src/styles/admin.css';
import * as api from '../../api/endpointURL';
import CustomTooltipDeleteUser from '../tooltips/CustomTooltipDeleteUser';
import Footer from '../Footer';
const deleteIcon = require('../../images/bin.png');


const AdminDashbaord = () => {  
  const [selectedBusiness, setSelectedBusiness] = useState('');
  //const [selectedSites, setSelectedSites] = useState([]);
  const [newUser, setNewUser] = useState('');
  const [loading, setLoading] = useState(false);  
  const [users, setusers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [isNewUserChecked, setIsNewUserChecked] = useState(false);
  const [isApiResponseOK, setIsApiResponseOK] =  useState(true);
  const [showDeleteUserWarning, setShowDeleteUserWarning] = useState(false);

  const { addToast } = useToasts();

  const isSmallScreen = window.innerWidth < 600;

  const user = localStorage.getItem('email');

  const navigate = useNavigate();

  const selectedUserData = useMemo(() => {
    return users.filter(user => user.email === selectedUser && user.business === selectedBusiness);
}, [users, selectedUser, selectedBusiness]);


  // Memoize userSites to prevent it from changing on every render
  const userSites = useMemo(() => {
    if (selectedUserData.length) {
        return selectedUserData[0].sites;
    }
    return [];
}, [selectedUserData]);

  const [checkedSites, setCheckedSites] = useState([]);

  useEffect(() => {
    if (isNewUserChecked) {
      setCheckedSites([]);
    }
    else {
      setCheckedSites(userSites);
    }    
  }, [userSites, isNewUserChecked]);



  // get unique user emails
  const uniqueUserEmails = [...new Set(users.map(user => user.email))]; 

  const fetchUserResponse = useSelector(state => state.fetchUserResponse);

  const businesses = fetchUserResponse?.businesses;

  const filteredSites = businesses?.filter(obj => obj[selectedBusiness] !== undefined);

  // Extracting values from filtered sites
  let selectedSites = [];
  
  if (selectedBusiness) {
     selectedSites = filteredSites?.flatMap(obj => Object.values(obj))[0];    
  }

  const businessNames = businesses?.map(business => Object.keys(business)[0]); 

  const base_url = api.url;  

  const dispatch = useDispatch();

  const userEmail = localStorage.getItem('email'); 
  
  // delete user confirmation
  const handleCloseDeleteWarning = () => {
    setShowDeleteUserWarning(false);    
  } 
  const handleShowDeleteWarning = () => {
    setShowDeleteUserWarning(true);
  } 


  const handleSelectChange = (event) => {
    setSelectedBusiness(event.target.value);
  };

  const handleSelectUserChange = (event) => {
    setSelectedUser(event.target.value);
  }

  const handleCheckboxChange = (site) => {  
    const updatedSites = checkedSites.includes(site) ? // Check if site is already in array
      checkedSites.filter(s => s !== site) : // If already checked, remove from array
      [...checkedSites, site]; // If not checked, add to array
    setCheckedSites(updatedSites);
  }; 

  const handleNewUser = (e) => {
    setCheckedSites([]);
    setNewUser(e.target.value);
  }

  const updateUserControl = async() => {
    try {
      let user = selectedUser;
      if (isNewUserChecked && newUser) {
        user = newUser;
      }
      const fetchOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: user, admin: userEmail, business: selectedBusiness, sites: checkedSites })
      };
    
    setLoading(true);
    await fetch(`${base_url}/invite-user`, fetchOptions);
    setLoading(false);
    if (isNewUserChecked) {
      let msg = `User with email ${newUser} is registered successfully.`;
      addToast(msg, {
        appearance: 'success',
        autoDismiss: true,
      });
      setIsNewUserChecked(false);
      setNewUser('');
    }
    else {
      let msg = `Access control is updated to user ${selectedUser}.`;
      addToast(msg, {
        appearance: 'success',
        autoDismiss: true,
      });
    }
   
    } catch (error) {
      console.log(error);
    }
  }

 

  // check if the entered user email already registered
  const isUserExist = (email) => {
    for (const user of users) {
      if (user.email === email) {
        return true;
      }
    }
    return false;
  }


  const registerNewUser = (e) => {
    e.preventDefault();
    if (isNewUserChecked && !isValidEmail(newUser)) {
      let msg = 'Entered email is not a valid email. Please enter a valid email for the new user.';
      addToast(msg, {
        appearance: 'error',
        autoDismiss: true,
      });
    }

    else if (isNewUserChecked && isUserExist(newUser)) {
      let msg = `The user ${newUser} is already registered. Please uncheck New User checkbox and select this user in the dropdown list.`;
      addToast(msg, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
    else if (isNewUserChecked && !checkedSites.length) {
      let msg = `Please assign at least one site to the new user ${newUser} `;
      addToast(msg, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
    else {
      updateUserControl();
      setCheckedSites([]);
    }   
  }

    // validating email addresses
    function isValidEmail(email) {
      // Regular expression pattern for validating email addresses
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      
      // Test the email against the pattern
      return emailPattern.test(email);
    }
  

  let siteTableLabel = "Enable site access to the new user:" ;
  let submitBtnLabel = "Invite User";
  
  if (!isNewUserChecked && users.length) {
    siteTableLabel = "Update site access to the selected user";
    submitBtnLabel = "Update Site Access"
  }

  const handleCheckedNewUser = (e) => {
    setNewUser('');
    if (isNewUserChecked && !users.length) {
      let msg = 'No user found in your account. Please register a user first.';
      addToast(msg, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
    else {
      setIsNewUserChecked(!isNewUserChecked);
    }   
  }

  // delete selected user from L3 table
  const handleDeleteUser = async() => {
    try {      
      const fetchOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: selectedUser, admin: userEmail})
      }
      setLoading(true);
      const response = await fetch(`${base_url}/delete-user`, fetchOptions);
      const data = await response.json();
      if (data.code === 200) {
        let msg = `User with email ${selectedUser} is deleted successfully.`;
        addToast(msg, {
          appearance: 'success',
          autoDismiss: true,
        });
        
      } 
      else {
        let msg = `Failed to delete user ${selectedUser} due to internal server error.`;
        addToast(msg, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
      setLoading(false);
      setShowDeleteUserWarning(false);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    dispatch(fetchUser(userEmail));
  }, [dispatch, userEmail]);


   // list existing L3 users
   useEffect(() => {
    const fetchOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ admin: userEmail })
    };

    const fetchUsers = async () => {
     
      try {
        const response = await fetch(`${base_url}/list-users-l3`, fetchOptions);
        if (!response.ok) {
          throw new Error('Failed to fetch users');          
        } else {
          setIsApiResponseOK(true);
        }
        const data = await response.json(); // Extract JSON data
        setusers(data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
   
      fetchUsers();    
   
  }, [users.length, base_url, userEmail, loading]);

  // set first business as default selected
  useEffect(() => {
    if (businessNames && !selectedBusiness) {
      setSelectedBusiness(businessNames[0]);
    }      
  }, [selectedBusiness, businessNames]);


  useEffect(() => {
    if (users.length === 0) {
      setIsNewUserChecked(true);

    } else {
      setIsNewUserChecked(false);
    }
  }, [users]);

  useEffect(() => {
    if (!user) {
        navigate('/auth/signin');
    }
 }, [navigate, user]);



  return (
    <>
    {!isSmallScreen && <div style={{ marginTop: '-3.3em' }}>
     <SideBar />
    </div>
    }
   
    <div className='admin-container'>
      {isApiResponseOK ? 
      <>
      <div className='admin-header'>
        <div className='admin-header-text'>
          Access Control Dashboard
        </div>
      </div>
      
       {/* select business dropdown menu */}
      <label className='select-label'>Select a business:</label>
      <select value={selectedBusiness} onChange={handleSelectChange} className='select-admin-dropdown'>    
        {businessNames?.map((businessName, index) => (
          <option key={index} value={businessName}>
            {businessName}
          </option>
        ))}
      </select>
      <br></br>

      <span>
        <span className='select-label'>New user?</span>
        <input 
          type='checkbox'
          checked={isNewUserChecked}
          onChange={handleCheckedNewUser}
        />
      </span>
      
      {
        isNewUserChecked && 
        <div>
        <input
        id='new-user-input'
        required
        type='email'
        value={newUser}
        onChange={handleNewUser}
        placeholder='Please provide the email address of the user you wish to invite'
      />
      
      </div>
      }     

      { (users.length && !isNewUserChecked) ?
      <div className='select-user-container'>
        <label className='select-user-label'>Select a user:</label><br></br>     
        <div className='select-user-dropdown-btn-container'>
          <select value={selectedUser} onChange={handleSelectUserChange} className='select-admin-user-dropdown'>
            <option >Select user</option>
            {uniqueUserEmails.map((user, index) => (
              <option key={index} value={user}>{user}</option>
            ))}
          </select>
          <button className='delete-user-btn' onClick={handleShowDeleteWarning}>
            <CustomTooltipDeleteUser text={'Delete this user'}>
              <img alt='Delete' src={deleteIcon} className='delete-icon'></img>
            </CustomTooltipDeleteUser>
          </button>   
        </div>       
      </div> : null
      }

     
     {selectedSites.length ?
     <>
     <div className='site-table-label'>{siteTableLabel}</div>
     <div className='table-container-admin'>
      <table className='admin-table'>
        <thead>
          <tr>
            <th>Site Name</th>
              {
               isNewUserChecked ? 
                <th>Assign Access</th> :
                <>
                <th>Update Access</th>
                </>
              }
           
          </tr>
        </thead>
        <tbody>
          { selectedSites?.map((site, index) => (
            <tr key={index}>
            <td>{site}</td>

            {
              isNewUserChecked ? 
              <td>              
                <input
                  type='checkbox'
                  checked={checkedSites.includes(site)}
                  onChange={() => handleCheckboxChange(site)}
                  />
              </td> : 
              <>                
                {/* <td>              
                  <input 
                   type='checkbox'
                   checked={userSites.includes(site)}
                   
                 />
                </td> */}
                <td>              
                  <input 
                  type='checkbox'
                  checked={checkedSites.includes(site)}
                  onChange={() => handleCheckboxChange(site)}
                 />
                </td> 
              </>
            }
            
        </tr>
          )) }
        </tbody>
      </table>
      </div> 
      <button className='new-user-register-btn' variant='secondary' type='button' onClick={ registerNewUser }>
        {submitBtnLabel}
      </button>
      </> : 
      <div className='no-site-label'>The selected business has no site</div>
      } 
      

      { loading && 
          <div style={{ textAlign: 'center', marginTop: '1em' }}>
            <BeatLoader size={10} color="#64C6E6"/>
          </div>}
      </> 
      : <span style={{ color: 'orange' }}>The server is temporarily unavailable. Please try again later!</span>
      }
    </div>

    <div className='admin-footer'>
      <Footer />
    </div>

        {/* modal to view delete user modal*/}

        <Modal size='sm' show={showDeleteUserWarning} onHide={handleCloseDeleteWarning}>
        <Modal.Header className='modal-color-header'>
        <div className='signup-modal-header-container'>
          <Modal.Title id='delete-user-modal-header'>Are you sure you want to delete the selected user <span>{selectedUser}</span></Modal.Title>
          <button className="custom-close" onClick={handleCloseDeleteWarning}>X</button>
        </div>
        </Modal.Header>

        <Modal.Body className='modal-color'>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="danger" size='sm' onClick={handleDeleteUser} style={{ width: '5em' }}>Yes</Button>
            <Button variant="secondary" size='sm' onClick={handleCloseDeleteWarning} style={{ width: '5em' }}>Cancel</Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
    
  )
}

export default AdminDashbaord