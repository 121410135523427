import * as Actions from "../constant/ActionTypes";

export const fetchSelectedBusiness = (selectedBusiness) => async (dispatch) => {
    try {
        dispatch({
            type: Actions.SELECTED_BUSINESS,
            payload: selectedBusiness
        })
    } catch (error) {
        console.log(error);
    }
}