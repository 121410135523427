// api routes
import axios from 'axios';

import { url } from './endpointURL';

export const API = axios.create({ baseURL: url});

export const listServers = (user) => API.post('/servers', user);

export const listDevices = (serverName, user) => API.post('/devices', serverName, user);

export const postACK = (ackData) => API.post('/ack', ackData);

export const postACKED = (ackData) => API.post('/acked', ackData);

export const listStorage = (serverName) => API.post('/storage', serverName);

export const listReport = (reportData) => API.post('/report', reportData);

export const isUserRegistered = (user) => API.post('/check_user', user);

export const fetchUser = (email) => API.post('/fetch_user', email);

export const addUser = (userData) => API.post('/add_user', userData);

export const optEmail = (optEmailData) => API.post('/opt_email', optEmailData);

export const optEmailCamera = (optEmailData) => API.post('/opt_email_camera', optEmailData);

export const businessTokenMapping = (user) => API.post('/business_token_mapping', user);

export const listBusinesses = (user) => API.post('/list-businesses', user);

export const listSites = (selectedBusinessData) => API.post('/list-sites', selectedBusinessData);

export const updateServerLifecycle = (selectedServerData) => API.post('/update-server-lifecycle', selectedServerData);

export const updateCameraLifecycle = (selectedCameraData) => API.post('/update-camera-lifecycle', selectedCameraData);

// LICENSE RELATED
export const listLicenses = () => API.get(`/licenses`);
export const createLicense = (newLicense) => API.post('/licenses', newLicense);
export const listLicenseUsers = () => API.get('/license_users');
export const suspendLicenses = (suspendData) => API.put('/licenses?id=suspend', suspendData);
export const reinstateLicenses = (reinstatedData) => API.put('/licenses?id=reinstate', reinstatedData);
export const changeLicensePolicy = (data) => API.put('/licenses?id=changePolicy', data);
export const deleteLicense = (deleteData) => API.delete(`/licenses/${JSON.stringify(deleteData)}`);

// USER RELATED
export const listUsers = () => API.get('/users');
export const createUser = (newUser) => API.post('/users', newUser);
export const updateUser = (id, updateUser) => API.post(`/users/${id}`, updateUser);
export const deleteUser = (id) => API.delete(`/users/${id}`);
