import * as Actions from '../constant/ActionTypes';

const fetchSelectedBusinessReducers = (selectedBusiness='', action) => {
    switch(action.type) {
        case Actions.SELECTED_BUSINESS:     
            return action.payload;

        default :
        return selectedBusiness;
    }
}
export default fetchSelectedBusinessReducers;