// ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

function ProtectedRoute({ children, requiredLevel, role }) {
  if (role !== requiredLevel) {
    return <Navigate to="/" replace />;
  }
  
  return children;
}

export default ProtectedRoute;
