import React, { useState } from 'react';

import SelectedCameraLifecycle from './SelectedCameraLifecycle';
import  './lifecycle.css';
import '../../styles/lifecycle.css';



const CameraLifecycle = ({ cameras, selectedPeriod, serverName, selectedCameraLifeExpectancyDays, selectedCriticalDaysCamera }) => {

const [sortConfigCameraLifecycle, setSortConfigCameraLifecycle] = useState({ key: 'operatingDays', direction: 'ascending' });   

const handleSortCameraLifecycle = (columnKey) => {
    let direction = 'ascending';
    if (sortConfigCameraLifecycle.key === columnKey && sortConfigCameraLifecycle.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfigCameraLifecycle({ key: columnKey, direction });
};

const renderSortIconCameraLifecycle = (columnKey) => {
    if (sortConfigCameraLifecycle.key === columnKey) {
      return sortConfigCameraLifecycle.direction === 'ascending' ? '▲' : '▼';
    }
    // Default to ascending arrow if not sorted yet
    return '▲';
  };

   // sort by all columns - alert data
   const sortedAllColumnsCameraLifecycle = [...cameras].sort((a, b) => {
    // if the key is operatingDays, we need to calculate the days passed
    if (sortConfigCameraLifecycle.key === 'operatingDays') {
        return sortConfigCameraLifecycle.direction === 'ascending' ? calculateDaysPassed(a.serverFirstCheckin) - calculateDaysPassed(b.serverFirstCheckin) : calculateDaysPassed(b.serverFirstCheckin) - calculateDaysPassed(a.serverFirstCheckin);
    }

    // if the key is daysSinceLastMaintenance, we need to calculate the days passed
    if (sortConfigCameraLifecycle.key === 'daysSinceLastMaintenance') {
        return sortConfigCameraLifecycle.direction === 'ascending' ? calculateDaysPassed(a.cameraLastMaintenance) - calculateDaysPassed(b.cameraLastMaintenance) : calculateDaysPassed(b.cameraLastMaintenance) - calculateDaysPassed(a.cameraLastMaintenance);
    }

    // if the key is remainingLifeExpectancy, we need to calculate the days passed
    if (sortConfigCameraLifecycle.key === 'remainingLifeExpectancy') {
        const remainingLifeExpectancyA = parseInt(selectedCameraLifeExpectancyDays) - calculateDaysPassed(a.cameraFirstCheckin);
        const remainingLifeExpectancyB = parseInt(selectedCameraLifeExpectancyDays) - calculateDaysPassed(b.cameraFirstCheckin);
        return sortConfigCameraLifecycle.direction === 'ascending' ? remainingLifeExpectancyA - remainingLifeExpectancyB : remainingLifeExpectancyB - remainingLifeExpectancyA;
    }

    if (a[sortConfigCameraLifecycle.key] < b[sortConfigCameraLifecycle.key]) {
    return sortConfigCameraLifecycle.direction === 'ascending' ? -1 : 1;
    }
    if (a[sortConfigCameraLifecycle.key] > b[sortConfigCameraLifecycle.key]) {
    return sortConfigCameraLifecycle.direction === 'ascending' ? 1 : -1;
    }
    return 0;
});

function calculateDaysPassed(startDateUTC) {
    // Parse the start date as a UTC date
    const start = new Date(startDateUTC);
    // Get the current date in UTC
    const now = new Date();
    // Calculate the difference in milliseconds
    const diffInMilliseconds = now - start;
    // Convert the difference to days
    const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
    return diffInDays;
}

  return (
    <div className='table-container-lifecycle-camera table-container-lifecycle-camera-new'>
    <table >
        <thead>
            <tr>               
                <th onClick={() => handleSortCameraLifecycle('cameraName')}>
                    Camera {renderSortIconCameraLifecycle('cameraName')}
                </th>
                <th onClick={() => handleSortCameraLifecycle('operatingDays')}>
                    Operating days {renderSortIconCameraLifecycle('operatingDays')}
                </th>
                <th onClick={() => handleSortCameraLifecycle('daysSinceLastMaintenance')}>
                    Total Days Since Last Maintenance {renderSortIconCameraLifecycle('daysSinceLastMaintenance')}
                </th>
                <th onClick={() => handleSortCameraLifecycle('remainingLifeExpectancy')}>
                    Total Remaining Life Expectancy Days {renderSortIconCameraLifecycle('remainingLifeExpectancy')}
                </th>
                <th>Update Maintenance Event</th>
            </tr>
        </thead>
        <tbody>
            {
                cameras && sortedAllColumnsCameraLifecycle.map(camera => (
                    <SelectedCameraLifecycle 
                        camera={camera}
                        selectedPeriod={selectedPeriod}
                        serverName={serverName}
                        selectedCameraLifeExpectancyDays={selectedCameraLifeExpectancyDays}
                        selectedCriticalDaysCamera={selectedCriticalDaysCamera}
                    />
                ))
            }
        </tbody>
    </table>
    </div>
  )
}

export default CameraLifecycle