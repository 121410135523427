

const Storage = ({ server }) => { 
  return (
    server?.storage?.map((eachStorage, index) => (
        <tr key={index}>
            <td>{server.site}</td>
            <td>{server.serverName}</td>
            <td>{eachStorage.storageId}</td>
            <td style={{ color: eachStorage.storageStatus === 'Online' ? "#32de84" : "#EF5B5B" }}>{eachStorage.storageStatus}</td>
        </tr>
    )    
    )
  )
}

export default Storage