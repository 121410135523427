import * as Actions from '../constant/ActionTypes';

const ackReducers = (ack={responseCode: 500}, action) => {
    switch(action.type) {
        case Actions.ACKED:
            return action.payload;

        default :
        return ack;
    }
}
export default ackReducers;