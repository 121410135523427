import * as Actions from '../constant/ActionTypes';

const serverReducers = (servers=[], action) => {
    switch(action.type) {
        case Actions.LIST_SERVERS:
            return action.payload;

        default :
        return servers;
    }
}
export default serverReducers;