import * as Actions from '../constant/ActionTypes';

const reportReducers = (report={}, action) => {
    switch(action.type) {
        case Actions.REPORT:
            return action.payload;

        default :
        return report;
    }
}
export default reportReducers;