import React, { useState } from 'react';

const CustomTooltipDeleteUser = ({ text, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div
      style={{ position: 'relative', display: 'inline-block' }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {showTooltip && (
        <div
          style={{
            position: 'absolute',
            top: '0%',
            left: '380%',
            transform: 'translateX(-50%)',
            color: 'orange',
            padding: '5px',
            borderRadius: '0px',
            zIndex: '999',
            fontSize: '0.7rem',
            width: '10rem'
                
          }}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default CustomTooltipDeleteUser;
