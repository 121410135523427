import * as Actions from '../constant/ActionTypes';

const updateServerLifecycleReducers = (response={}, action) => {
    switch(action.type) {
        case Actions.UPDATE_SERVER_LIFE_CYCLE:
            return action.payload;

        default :
        return response;
    }
}
export default updateServerLifecycleReducers;