import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import '../charts/lifecyclecharts.css';

const ServerStatusChart = ({ data, setIsSwitchOnServerExpired, setIsSwitchOnServerCritical, setIsSwitchOnServerHealthy, averageOperatingDaysServers }) => {

    const healthy = data.datasets[0].data[0];
    const critical = data.datasets[0].data[1];
    const expired = data.datasets[0].data[2];
    const total = healthy + critical + expired;

    const handleClick = (event, elements) => {
        if (elements.length > 0) {
            const { index } = elements[0];
            const label = data.labels[index];
            const value = data.datasets[0].data[index];
            console.log(`Label: ${label}, Value: ${value}`);

            if (label === 'Critical') {
                setIsSwitchOnServerExpired(false);
                setIsSwitchOnServerHealthy(false);
                setIsSwitchOnServerCritical(prev => !prev);                
            } else if (label === 'Expired') {
                setIsSwitchOnServerHealthy(false);
                setIsSwitchOnServerCritical(false);
                setIsSwitchOnServerExpired(prev => !prev);
            } else if (label === 'Healthy') {
                setIsSwitchOnServerExpired(false);
                setIsSwitchOnServerCritical(false);
                setIsSwitchOnServerHealthy(prev => !prev);
            }
        }
    };



    return (
    <div className='lifecycle-chart-container'>
      
            <div className='lifecycle-legend'>
                <div className='lifecycle-legend-item'>
                    <div className='lifecycle-legend-item-value-healthy'>{healthy}</div>
                    <div className='lifecycle-legend-item-label'>Healthy</div>    
                </div>               
                <div className='lifecycle-legend-item'>
                    <div className='lifecycle-legend-item-value-critical'>{critical}</div>
                    <div className='lifecycle-legend-item-label'>Critical</div>                    
                </div>
                <div className='lifecycle-legend-item'>
                    <div className='lifecycle-legend-item-value-expired'>{expired}</div>
                    <div className='lifecycle-legend-item-label'>Expired</div>                    
                </div>
            </div>
            <div >
                <div className='lifecycle-legend-item-value-total'>{total}</div>
                <div className='lifecycle-legend-item-label-total'>Total</div>
            </div>
      
        <div className='center-align-chart'>
            <Doughnut
                data={data}
                options={{
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                        display: false, // Hides the legend
                        },
                    },
                    tooltips: {
                        callbacks: {
                            label: function(tooltipItem, data) {
                            let label = data.labels[tooltipItem.index];
                            let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                            return `${label}: ${value}`;
                            }
                        }
                    },
                    onClick: (event, elements) => handleClick(event, elements),
                }}
            />
        </div>
        <div className='lifecycle-chart-footer'>
            Servers
        </div>
        <div className='lifecycle-legend-item-value-total-container'>
            <div className='lifecycle-legend-item-value-total'>{averageOperatingDaysServers}</div>
            <div className='lifecycle-legend-item-label-total'>Average Operating Days</div>
        </div>
    </div>
)};

export default ServerStatusChart;
