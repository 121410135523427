import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listServers } from './redux/actions/serverActions';
import { listDevices } from './redux/actions/deviceActions';

const ScheduledUpdate = () => {
  const dispatch = useDispatch();
  const selectedBusiness = useSelector(state => state.selectedBusinessReducer);

  // Function to update servers info
  const fetchServersAndUpdate = () => { 
    const user = localStorage.getItem('email');
    if (user && selectedBusiness) {
      dispatch(listServers({ user, selectedBusiness }));
    }
  };

  // Function to update devices info
  const fetchDevicesAndUpdate = () => {
    const selectedServerName = localStorage.getItem('selectedServerName');
    if (selectedServerName) {
      dispatch(listDevices(selectedServerName));
    }
  };

  useEffect(() => {
    // Set intervals for fetching data
    const serverInterval = setInterval(fetchServersAndUpdate, 5 * 60 * 1000); // 12 seconds
    const deviceInterval = setInterval(fetchDevicesAndUpdate, 5 * 60 * 1000); // 5 minutes

    // Cleanup intervals on component unmount
    return () => {
      clearInterval(serverInterval);
      clearInterval(deviceInterval);
    };
  }, [selectedBusiness]); // Dependency array includes selectedBusiness to re-run effect when it changes

  return null; // No UI to render
};

export default ScheduledUpdate;
